import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"
import Paper from "../../../../../components/paper"
import MarkerHighlight from "../../../../../components/marker-highlight"

const Review = ({ author, children }) => {
  /* random number between 1 and 4 */
  const randomNumber = Math.floor(Math.random() * (4 - 1 + 1) + 1)

  /* angles for rotated box */
  const angles = {
    1: "-1deg",
    2: "-0.5deg",
    3: "0.5deg",
    4: "1deg",
  }

  const randomAngle = angles[randomNumber]

  return (
    <Paper
      sx={{
        p: [4, 4, 6],
        transform: `rotate(${randomAngle})`,
      }}
    >
      <Stack space={6}>
        <Text size={[3, 3, 4]} mono>
          {children}
        </Text>
        <Box sx={{ width: 4, height: 1, bg: "border" }} />
        <Text sans size={3} color="inherit">
          {author}
        </Text>
      </Stack>
    </Paper>
  )
}

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/gedichtrezension" />
      }
    >
      <Seo title="Das Gedicht in der Kritik" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Das Gedicht in der Kritik
            </Heading>
          </Stack>
          <Paragraph>
            Der Zyklus wurde in Friedrich Wilmanns ›Taschenbuch für das Jahr
            1805‹ veröffentlicht, das »der Liebe und Freundschaft gewidmet« war.
            Solche Gedichtsammlungen waren um 1800 sehr beliebt. Sie richteten
            sich vor allem an ein weibliches Publikum, da Frauen in dieser Zeit
            den größten Teil des Lesepublikums ausmachten. Überhaupt konnte nur
            ein kleiner Teil des Gesellschaft lesen und schreiben. Männer lasen
            überwiegend Sachtexte, während sich Frauen der sogenannten »schönen
            Literatur« widmeten – vor allem dem Roman, der als Gattung gerade
            erst erfunden war.
          </Paragraph>
          <Paragraph>
            Hölderlins Gedichte wurden im Taschenbuch also neben Gedichten,
            Gesängen, Kurzgeschichten und Illustrationen abgedruckt, die von
            Liebe und Freundschaft handelten, und werden die Leser*innen wohl
            ziemlich überfordert haben.
          </Paragraph>
          <Paragraph>
            Das sagten die damaligen Rezensenten über Hölderlins Gedichte:
          </Paragraph>
          <Review author="unbekannt">
            Für{" "}
            <MarkerHighlight color="negative">
              den seltenen Sterblichen, der die neun Gedichte von Hölderlin zu
              verstehen sich mit Recht rühmen kann, sollte ein stattlicher Preis
              ausgesetzt werden
            </MarkerHighlight>
            , und wir würden selbst den Verfasser nicht von der Mitbewerbung
            ausschließen. Nichts erregt mehr Unwillen, als Nonsens mit
            Prätension gepaart.
          </Review>
          <Review author="Garlieb Merkel">
            Unter den Gedichten sind […] neun versificirte Radottagen{" "}
            <MarkerHighlight color="negative">
              [Faseleien] von Hölderlin höchst lächerlich
            </MarkerHighlight>
            .
          </Review>
          <Review author="Karl Philipp Conz">
            Die Gedichte von Hölderlin füllen eine eigene Rubrique [...] von den
            übrigen getrennt. Nicht ohne Grund! denn sie sind Wesen eigener Art
            und{" "}
            <MarkerHighlight color="negative">
              erwecken ganz vermischte Gefühle
            </MarkerHighlight>
            . Es scheinen abgerissene Laute eines gestörten einst schönen Bundes
            zwischen Geist und Herz. Daher auch die Sprache schwerfällig,
            dunkel,{" "}
            <MarkerHighlight color="negative">
              oft ganz unverständlich
            </MarkerHighlight>{" "}
            und der{" "}
            <MarkerHighlight color="negative">
              Rythmus eben so rauh
            </MarkerHighlight>
            . Wie viel besser ist das erst vor kurzem im III.Heft der
            Huber'schen Unterhaltungen von Hölderlin gedrukte, wenn schon auch
            zu viel Spannung einer ans Kränkelnde streifenden Sehnsucht
            verrathende Gedicht der Archipelagus, das aber aus einer früheren
            Periode des Verf[assers] datirt.
          </Review>
          <Review author="Johann Friedrich Schink">
            Mit dem poetischen Blumenwesen dieses Büchleins sieht es etwas
            dürftig aus. […]{" "}
            <MarkerHighlight color="negative">
              nicht viel mehr, als poetischer Phrasenkram und Reimklingklang
            </MarkerHighlight>
            . Der{" "}
            <MarkerHighlight color="negative">
              Herausgeber muß sich hier durchaus einer strengeren Auswahl
              befleißigen
            </MarkerHighlight>
            , wenn Freunde der Dichtkunst von Geschmack an seiner Lese Gefallen
            finden sollen.
          </Review>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
